.atom_answer{
    padding:0px 14px;
    border-radius:40px;
    color:$color_blue;
    width:auto;
    float:right;
    margin:20px 0;
    position:relative;
    transition:1s all;
    overflow:hidden;
    border:2px solid $color_xl_blue;
    background:$color_xl_blue;
    &.empty{
        border:2px solid $color_coral;
        background:$color_xl_coral;
    }
    p{
        font-size:15px;
        &::first-letter {
            text-transform: uppercase;
          }
    }
    background:$color_white url("../img/icons/icon_edit.svg") no-repeat center right -70px;
    &.hasHover{
        &:hover{
            padding-right:40px;
            background:$color_white url("../img/icons/icon_edit.svg") no-repeat center right 10px;
        }
    }
    &_mini{
        border:none;
        background-color: transparent;
        color:$color_blue;
        font-weight:900;
        padding:0px 5px;
        float:left;
        margin:0 0;
        margin-bottom:5px;
        font-size:10px;
        display:flex;
        align-items: center;
        justify-content: center;
        height:14px;
        max-width:12px;
        cursor:pointer;
        border-radius:0;
        border-right:1px solid $color_lgrey;
        &.disabled{
            color:$color_lgrey;
        }
        &.empty{
            color:$color_coral;
            border:none;
            background-color: transparent;
        }
        &:hover{
            color:$color_green;
            padding-right:5px;
            background:none;
        }
    }
    
    small{
        font-size:10px;
        text-transform: uppercase;
        margin-bottom:0px;
    }
    p{
        padding:0;
        &.info{
            font-size:12px;
            font-style: italic;
        }
        img{
            height:18px;
            margin-left:10px;
            margin-bottom:-4px;
            cursor:pointer;
        }
    }
}

.block{
    width:100%;
    display:block;
    clear:both;
}

.atom_mini_responses{
    border-radius:20px;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    justify-content: center;
    max-height:5%;
   
    .inner{
        width:100%;
        box-sizing: border-box;
        display:flex;
        .buttons{
            width: 20%;
            text-align: right;
            .btn{
                background-size:15px ;
                height:30px;
                width:30px;
            }
        }
        .btns{
            padding-right: 10px;
            min-width:90%;
            padding-top:2px;
            @media (max-width: $break-small) {
                min-width:85%;
            }
            span{
                float:left;
                font-size:10px;
                font-weight:900;
                color:$color_blue;
                display:none;
            }
        }
        .scroll-buttons{
            max-width:70px;
            @media (max-width: $break-small) {
                margin-right:20px;
                max-width:90px;
            }
        }
    }
}