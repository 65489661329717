.atom_document{
    &_edit{
        padding:30px;
        width:100%;
        max-width:780px;
        box-sizing: border-box;
        margin:0 auto;
        background:$color_white;
        height:100vh;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
    }
    .paper{
        background:$color_white;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
        padding:40px;
        box-sizing: border-box;
        width:100%;
        user-select: none; // Disable text selection using CSS
        -webkit-user-select: none; // For Webkit browsers
        -moz-user-select: none; // For Firefox
        -ms-user-select: none; // For Internet Explorer/Edge

    }
    .tbc{
        color:$color_coral;
    }
    &_area{
        .paper{
            background:$color_white;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
            padding:40px;
            box-sizing: border-box;
            width:100%;
            user-select: none; // Disable text selection using CSS
            -webkit-user-select: none; // For Webkit browsers
            -moz-user-select: none; // For Firefox
            -ms-user-select: none; // For Internet Explorer/Edge
            font-size:14px;
            line-height:20px;
            font-family: "Inter";
            .proposal_wrap{
                width:100%;
                border-top:2px solid $color_lgrey;
                border-bottom:2px solid $color_lgrey;
                padding:40px;
                margin:20px 0;
                box-sizing: border-box;

                h2{
                    text-align:center;
                    font-size:25px;
                }
            }

        }
        .tbc{
            color:$color_coral;
        }
        th{
            padding:10px 0;
            
            border-bottom:2px solid $color_lgrey;
            border-top:2px solid $color_lgrey;
            &:last-child{
                text-align: right;
            }
        }
        width:100%;
        position:relative;
        box-sizing: border-box;
        height: 87vh;
        overflow-y: auto;
        background:$color_lgrey;
        padding:30px;
            text-align:left;
            font-size:12px;
            line-height:17px;
            font-family: arial;
        hr{
            margin:70px auto 20px;
            color:$color_lgrey;
            border-color:$color_lgrey;
            background-color: $color_lgrey;
            opacity:0.4;
            width:60%;

        }
        h1{
            text-align: left;
            text-transform:uppercase;
            letter-spacing:-3px;
            font-size: 60px;
            font-family: "Inter";
            font-weight:900;
            padding: 40px 20px 0 0;
            margin-bottom:0px;
            margin-top:190px;
        }
        h2{
            text-align:left;
            font-size:18px;
            text-transform:uppercase;
            color:$color_lgrey !important;
            font-family: "Inter";
            padding:40px 0px 8px 0;
            margin:0;

        }
        h3{
            text-align:center;
            font-size:18px;
            font-family: "Inter";
            padding:10px 0px;
            margin:0;
        }
        h4{
            text-align:left;
            font-size:16px;
            font-family: "Inter";
            padding:25px 0px 0px 0;
            margin:0;
        }
        h5 {
            text-align: left;
            font-size: 14px;
            font-family: "Inter";
            padding: 15px 0 0 0;
            margin: 0 0 -10px;
            font-weight: 700;
        }
        p, li{
            text-align:left;
            font-size:14px;
            line-height:20px;
            font-family: "Inter";
            padding:10px 0px 0 0;
            margin:0;
        }
        blockquote{
            font-family: "Inter";
            text-align:center;
            font-weight:bold;
            font-size:14px;
            line-height:20px;
            background:#eaeaea;
            width:100%;
            box-sizing:border-box;
            padding:20px 20px;
            margin:20px 0 20px 0;
        }
        table{
            width:100%;
            border:0;
            font-family: "Inter";
            border-collapse: collapse;
            margin:20px 0;
            border-bottom:2px solid $color_lgrey;
            
            tr{
                &:first-child{
                    td{
                        border-top:1px solid $color_lgrey;   
                    }
                }
            }
            td{
                border-bottom:1px solid $color_lgrey;
                padding:10px 0;
                font-size:12px;
                font-family: "Inter";
                &:last-child{
                    text-align: right;
                    p{
                        text-align: right;
                    }
                }
                p{
                    font-size:12px;
                    padding:0;
                }
                
                
            }
        }
    }
    &_buttons{
        height:10vh;
        border-top:1px solid $color_lgrey;
        button{
            background:$color-blue;
            font-size:11px;
            border-radius:20px;
            padding:5px 10px;
            margin:10px 5px 10px 0;
            color:$color_white;
            font-family: $font_secondary;
            border:none;
        }
    }
}