.atom_link{
    &-large{
        color:$color_white !important;
        font-size:60px;
        font-weight:700;
        transition:1s all;
        text-transform: uppercase;
        font-family: $font_primary;
        margin-top:10px;
        margin-bottom:-20px;
        width:100%;
        display:block;
        &:hover{
            color:$color_coral !important
        }
    }
}