.atom_title{
    .row{
        padding-bottom:0;
         margin-bottom:0;
         padding-top:0;
         .col-xs-12{
            padding-bottom:0;
            margin-bottom:0;
            padding-top:0;
         }
    }
}