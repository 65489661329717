.layout_navigation{
    height:100%;
    border-radius:10px;
    text-align: center;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.mobile{
        display:none;
    }
    @media (max-width: $break-small) {
    display:none;
        &.mobile{
            display:block;
            width:20%;
            position:absolute;
            top:-200%;
            left:0;
            border-radius:0;
            transition:1.5s all;
            z-index:1;
            .avatar{
                .inner{
                    margin:0 auto;
                }
            }
            &.open{
                top:30px;
            }
        }
    }
    
    .icons{
        margin-top:50px;
        margin-bottom:50px;
        .icon{
            margin:30px auto 30px auto;
            display:block;
            transition:1s all;
            border-radius:100%;
            padding:10px;
            height:20px;
            width:20px;
            display:flex;
            &:hover{
                background-color:$color_green;
            }
            &.active{
                background-color:$color_green;
            }
        }
    }

    .avatar{
        margin:0 auto;
       
        .inner{
            transition:1s all;
            cursor: pointer;
            border-radius:100%;
            border:2px solid $color-white;
            background:$color_xl_blue url("../img/icons/icon_user.svg") no-repeat center center;
            background-size:50%;
            height:15px;
            width:15px;
            padding:14px;
            &:hover{
                background-color:$color_green
            }
        }

    }
}