.atom_transitions{
    height:100vh;
    width:100vw;
    display:flex;
    align-items: center;
    justify-content: center;
    .logo{
        opacity:0.2
    }
    .layer_one{
        background:$color_coral;
        width:100%;
        height:100%;
        background:rgba(255,255,255,0.1);
    }
    .layer_two{
        background:$color_coral;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.1);
    }
}

.atom_loading{
    width:100%;
    display:flex;
    height:100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    .video-container{
        text-align: center;
        width:100%;
        margin:0;
        video{
            max-width:300px;
            max-height:300px;
            margin:0 auto;
        }
    }
    .inner{
        box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
        background:rgba(255,255,255,1);
        border-radius:20px;
        padding:30px;
        width:auto;
        display:flex;
        flex-direction: column;
        img{
            margin:0 auto !important;
        }
    }
    &.no-box{
        .inner{
            box-shadow:none;
            background:transparent;
        }
    }
    img{
        max-width:100px;
        margin:0 auto !important;
    }
    input[type="file"] {
        display: none;
    }
    .btn.file-upload{
        position:relative;
        z-index:9999999;
    }
    .input-file {
        cursor:pointer;
        border:none;
        position:relative;
        z-index:9999999;
        border-bottom:2px solid $color_lgrey;
        background:transparent url('../img/icons/icon_file_upload.svg') no-repeat center left;
        padding-left:30px;
        background-size:18px;
        font-size:12px;
        font-family: $font_secondary;
        font-weight:400;
        color:$color_grey;
        text-align: left;
        padding-bottom:10px;
        padding-top:10px;
        width:60%;
        display:inline-block;
        transition:1s all;
        &:hover{
            color:$color_green;
        }
      }
      .btn{
        padding:10px 20px !important;
      }
    &.with_opacity{
        height:80vh;
        position:absolute;
        top:0;
        right:0;
    }
    h1,h2,h3{
        text-align: center;
    }
    h2{
        font-size:18px;
        text-transform: uppercase;
    }
}