@import "variables/main.scss";
@import "mixins/main.scss";
@import "grid";
@import "visibility"; // Visibility last for precedence

.container{
    //max-width:1280px;
    margin:0 auto;
}



