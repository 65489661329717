.layout_profile{
    .buttons{
        &_wrap{
            .btn{
                margin:10px;
            }
        }
        display:flex;
        .btn{
            margin:5px;
        }
    }
    ul{
        padding:0;
        margin:0;
        margin-top:20px;
        li{
            list-style: none;
            margin-bottom:20px;
            a{
                text-decoration: none !important;
            }
            .active{
                font-weight:700;
            }
        }
    }
}