.atom_spec{
    
    &_area{
        height:100%;
        display:flex;
        padding-top:70px;
        flex-direction:column;
        box-sizing: border-box;
        overflow: hidden;
    }
    &.top_bar{
        width:100%;
    }
    &_action_buttons{
        padding:0;
        text-align: left;
        margin:0 0 0 10px;;
        position:absolute;
        top:20px;
        display:flex;
        background:$color_white;
        border-radius:20px;
        padding:5px 20px;
        justify-content: center;
        align-items: center;
        .title{
            width:150px;
            font-size:12px;

        }
        .btn{
            padding:5px 10px;
            font-size:11px;
        }
        
    }
    &_actions{
        display:flex;
        min-width:80%;
        margin-top:10px;
    }
    &_buttons{
        width:100%;
        height:20%;
        @media (max-width: $break-small) {
            width:70%
        }
        .btn{
            float:right;
            margin-left:5px;
        }

    }
    &_title_area{
        width:100%;
        display:block;
        height:10%;
        max-height:90px;
    }
    &_question_area{
        width:100%;
        min-height:250px;
        height:60%;
        h4{
            text-align: center;
            color:$color_grey
        }
        .buttons{
            display: flex;
            justify-content: right;
            align-items: right;
        }
    }
    &_question_completed{
        background:$color_white;
        padding:20px;
        border-radius:20px;
        text-align:left;
        display:flex;
        justify-content: center;
        align-items: center;
        .inner{
            max-width:90%;
            min-width:80%;
            padding-left:20px;
        }
        h3{
            font-size:16px;
        }
        
        p{
            font-size:14px;
        }
    }
    &_end{
        width:100%;
        margin-bottom:0;
        .atom_input{
            position:relative;
            display: flex;
            align-content: flex-end;
            justify-content: flex-end;
            min-height:40px;
            margin-top:55px;
            margin-bottom:0px;
            input{
                background-color:rgba(255,255,255,0.4);
            }
            textarea{
                max-height:200px;
                min-height:20px;
                vertical-align: middle;
            }
            
        }
    }
    &_title{
        margin:0px 0 10px 0;
        width:100%;
        box-sizing: border-box;
        border:none;
        border-radius:20px;
        background:rgba(255,255,255,0.6) url('../img/icons/icon_edit.svg') no-repeat center right 20px;
        padding:10px;
        font-size:24px;
        border:2px solid $color_xl_blue;
        font-family:$font_primary;
    }
}

.action_bar{
    display:flex;
    position:relative;
    margin-bottom:-30px;
    z-index:99999;
    width:100%;
    box-sizing: border-box;
    align-items: center;
    .btns{
        width:90%;
        padding:8px 0 3px 0;
        background:$color_xl_blue;
        -webkit-border-top-right-radius: 20px;
        -moz-border-radius-topright: 20px;
        border-top-right-radius: 20px;
        padding-left:10px;
    }
}
.scroll-buttons {
    display: flex;
    justify-content: center;
    max-width:70px;
    width:10%;
    background:$color_white;
    padding:5px 5px 5px 10px;
    -webkit-border-top-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    border-top-left-radius: 20px;

    button {
        background:$color_blue url('../img/icons/arrow_down.svg') no-repeat center center;
        width:20px;
        height:20px;
        border:0;
        text-indent:-9999px;
        overflow: hidden;
        border-radius:100%;
        background-size:50%;
        margin-right:5px;
        cursor:pointer;
        &:last-child{
            transform:rotate(180deg);
        }
    }

    button:hover {
        background-color: $color_grey;
    }
}

.atom_flexible_responses {
    position: relative;
    height:100%;
    max-height:100%;
    overflow: hidden;
    scroll-behavior: smooth;
    width:100%;
    background:rgba(255,255,255,0.6);
    border:2px solid $color_xl_blue;
    border-radius:20px;
    padding:20px;
    box-sizing: border-box;
    transition: 1s all;

    &.white_bg{
        background:rgba(255,255,255,1);
    }
    @media (max-width: $break-small) {
        max-height:250px;
    }
    h4{
        font-size:20px;
        color:$color_grey;
        text-align: center;
        margin-top:120px;
    }
}