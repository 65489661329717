.atom_upper{
    padding:10px;
    &:first-child{
        
        @media (min-width: $break-small) {
            padding-left:0 !important;
        }
    }
    &:last-child{
        @media (min-width: $break-small) {
            padding-right:0 !important;
        }
    }

}
.atom_cards{
    padding:28px 20px;
    border-radius:20px;
    
    &_responses{
        height:100%;
    }
    &_spec{
        background:$color_white;
        padding:18px 20px;
        margin-bottom:15px;
        h5{
            margin-top:0px;
        }
        p{
            padding-bottom:0 !important;
            margin-bottom:0;
        }
    }
    &_price{
        box-sizing: border-box;
        border-radius:10px;
        padding:20px;
        width:100%;
        transition:1s all;
        cursor:pointer;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
    
        &:hover{
            box-shadow: rgba(0, 0, 0, 0.65) 0px 25px 20px -20px;
        }
        a{
            text-decoration:none !important;
        }
        .title{
            font-family: $font_primary;
            font-weight:500;
            font-size:36px;
        }
        .price{
            font-family: $font_secondary;
            font-weight:300;
            font-size:30px;
        }
        ul{
            margin:20px 0;
            padding:0;
            li{
                list-style: none;
                padding-left:26px;
                margin-left:0;
                font-size:14px;
                line-height:22px;
                margin-bottom:10px;
                background:url("../img/icons/icon_check_blue.svg") no-repeat center left -5px;
            }
        }
        &_light{
            background:$color_white;   
        }

        &_dark{
            background:$color_blue;
            color:$color_white;
            ul{
                li{
                    background:url("../img/icons/icon_check_green.svg") no-repeat center left -5px;
                }
            }
        }
    }
    &_create{
        border:2px dashed $color_blue;
        background:$color_white;
        text-align: center;
        transition:1s all;
        cursor:pointer;
        &:hover{
            border-color:$color_green;
        }
    }
    &_document{
        padding:15px 20px;
        border:2px solid $color_lgrey;
        transition:1s all;
        &:hover{
            border:2px solid $color_blue;
        }
        .date{
            font-size:12px;
            color:$color_grey;
            margin:10px 0;
            img{
                margin-right:10px;
                height:20px;
                margin-bottom:-5px;
            }
        }
        .title{
            display:flex;
            justify-content: space-between;
            .imgwrap{
                background-color:$color_xl_blue;
                border-radius:20px;
                width:30px;
                height:30px;
                display:flex;
                align-items: center;
                justify-content: center;
            }
            img{
                height:15px;
                cursor:pointer;
                
                &:hover{
                    border-color:$color_coral
                }
            }
            p{
                font-family: $font_primary;
                font-size:18px;
                width:80%;
                padding-top:0;
                padding-bottom:0;
                margin:0;
                font-weight:500;

            }

            
        }
    }
    &_plan{
        .no_plans{
            text-align: center;
            h4{
                margin-bottom:50px;
            }
            .btn{
                margin-top:30px;
                display: inline-block;
                text-decoration: none !important;
                a{
                    text-decoration: none;
                }
                
            }
        }
        padding:15px 20px;
        margin-bottom:20px;
        background:$color_white;
        transition:1s all;
        table{
            width:100%;
            tr{
                margin:5px 0;
                th{
                    font-size:14px;
                    font-family: $font_secondary;
                    color:$color_white;
                    background-color: $color_blue;
                    font-weight:600;
                    border-radius:20px;
                    padding:10px 10px; text-align:center;
                }
                td{
                    font-size:14px;
                    font-family: $font_secondary;
                    color:$color_blue;
                    background-color: $color_xl_blue;
                    font-weight:400;
                    border-radius:20px;
                    margin:5px 0 !important;
                    
                    padding:10px 10px; text-align:center;
                }
            }
        }
        
        .date{
            font-size:12px;
            color:$color_grey;
            margin:10px 0;
            img{
                margin-right:10px;
                height:20px;
                margin-bottom:-5px;
            }
        }
        .atom_toggle_title{
            font-weight:600;
        }
        .revision{
            display:flex;
            .timestamp{
                width:55%;
            }
            a{
                text-decoration: none !important;
                width:45%;
                text-align: right;
                
            }
        }
        .title{
            p{
                font-family: $font_primary;
                font-size:18px;
                width:100%;
                padding-top:0;
                padding-bottom:0;
                margin:0;
                font-weight:500;

            }
            small{
                font-family: $font_secondary;
                font-size:11px;
            }
            
        }
    }
   
   
}