.atom_account{
    position:absolute;
    top:40px;
    right:20px;
    width:200px;
    z-index:999999;
    cursor:pointer;
    @media (max-width: $break-small) {
        width:100%;
        right:0;
        top:0;
        background:$color_white;
        padding:20px 0;
    }
    .menu{
        display:none;
        @media (max-width: $break-small) {
            display:block;
        }
        background:$color_green;
        border-radius:10px;
        height:40px;
        width:40px;
        background:$color_green url('../img/icons/icon_menu.svg') no-repeat center center;
        background-size:20px;
        margin-left:20px;

    }
    &_title{
        cursor: pointer;
        text-align: right;
        padding-right:20px;
        z-index:9999999999;
        @media (max-width: $break-small) {
            margin-top:-30px;
            width:50%;
            float:right;
        }
        img{
            float:right;
            margin-left:20px;
            transform:rotate(-90deg);
            transition: 1s all;
            margin-top:-2px;
            &.closed{
                transform:rotate(90deg);
            }
        }

    }
    &_toggle{
        opacity:0;
        transition:1s all;
        top:-300%;
        position:relative;

        &.closed{
            top:-300%;
            opacity:0;
            display:none;
        }
        &.open{
            top:0%;
            opacity:1;
            display:block;
            .atom_account_content{
                margin-top:10px !important;
                opacity:1 !important;
            }

        }
    }
    &_content{
        background:white;
        border-radius:10px;
        padding:10px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
        transition:1s all;
       
        opacity:0;
        @media (max-width: $break-small) {
            box-shadow:none;
            border-radius:0;
            margin-top:-100%;
        }
        
        .link_section{
            width:100%;
            margin:10px 0;
            .link{
                &-settings{
                    cursor: pointer;
                    width:100%;
                    display:block;
                    background: url("../img/icons/icon_settings.svg") no-repeat center left;
                    padding-left:25px;
                    background-size:contain;
                    margin:20px 0;
                    transition:1s all;
                    text-decoration:none !important;
                    a{
                        text-decoration:none !important;
                    }
                    &:hover{
                        color:$color_green;
                    }
                }
                &-logout{
                    cursor: pointer;
                    width:100%;
                    display:block;
                    background: url("../img/icons/icon_logout.svg") no-repeat center left;
                    background-size:contain;
                    padding-left:25px;
                    margin:20px 0 0 0;
                    transition:1s all;
                    &:hover{
                        color:$color_green;
                    }
                }
            }
        }
        .avatar_section{
            width:100%;
            display:block;
            clear:both;
            display:flex;
            .name{
                width:60%;
                font-size:16px;
                padding:0px 0;
                font-weight:500;
                align-content:center;
                font-family: $font_primary;
            }
            .avatar{
                width:35%;
                height:30px;
                .inner{
                    transition:1s all;
                    cursor: pointer;
                    border-radius:100%;
                    border:2px solid $color-white;
                    background:$color_xl_blue url("../img/icons/icon_user.svg") no-repeat center center;
                    background-size:50%;
                    height:20px;
                    width:20px;
                    padding:8px;
                }
            }
        }
    }
}