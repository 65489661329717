$color_black: #0A0A0C;
$color_dgrey: #222222;
$color_lgrey: #999999;
$color_coral: #FE5260;
$color_xl_coral:#f69ea5;
$color_green: #c3e72c;
$color_xl_green: #dfeab2;
$color_white: #ffffff;

$color_blue: #112051;
$color_xl_blue: #d8e0f4;

$color_warning: #f56b6b;
$color_success: #77dc6f;

$color_grey: #666;

.bg{
    &_black{
        transition:all 1s;
        background:$color_black;
        color:$color_lgrey;
        p, h1,h2,h3,h4,h5,h6,.h7{
            color:$color_lgrey;
        }
        .elem_button--link{
            border-color:$color_lgrey;
        }
    }
    &_blue{
        background:$color_blue;
    }
    &_gradient{
        &_xlblue{
            background: linear-gradient(180deg, $color_xl_blue 0%, $color_white 100%);
        }
        &_xlblue_hor{
            background: linear-gradient(90deg, $color_xl_blue 0%, $color_white 100%);
        }
    }
    &_dark{
        transition:all 1s;
        background-color:$color_black;
        color:$color_lgrey;
        p, h1,h2,h3,h4,h5,h6,.h7{
            color:$color_lgrey;
        }
        .elem_button--link{
            border-color:$color_lgrey;
        }
    }
    &_dgrey{
        transition:all 1s;
        background-color: $color_dgrey;
        .elem_button--link{
            color:$color_black;
            &:after{
                background-color:$color_black;
            }

        }
    }
    &_light{
        transition:all 1s;
        background-color:$color_lgrey;
        color:$color_black;
        p, h1,h2,h3,h4,h5,h6,.h7{
            color:$color_black;
        }
    }
    &_lgrey{
        transition:all 1s;
        background:$color_lgrey;
    }
    &_white{
        transition:all 1s;
        background:white;
    }
    &_red{
        transition:all 1s;
        background:$color_coral;
    }
    &_green{
        transition:all 1s;
        background:$color_green;
    }
    
}

.text{
    &_left{
        text-align: left;
    }
    &_center{
        text-align: center;
    }
    &_right{
        text-align: right;
    }
    &_black{
        color:$color_black;
    }
    &_lgrey{
        color:$color_lgrey !important; 
    }
    &_dgrey{
        color:$color_dgrey !important; 
    }
    &_grey{
        color:$color_grey !important; 
    }
    &_white{
        color:$color_white !important;
    }
    &_blue{
        color:$color_blue !important;
    }
}

