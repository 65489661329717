.atom_question{
    width:100%;
    margin:20px 0;
    display:block;
    &_mini{
        padding:4px 20px 4px 5px;
        border-radius:40px;
        background:$color_blue;
        color:$color_white;
        width:auto;
        clear:both;
        font-size:15px;
        float:left;
        display:flex;
        justify-content: center;
        align-items: center;
        &_inner{
            min-width:75%;
            padding:10px 0;

        }
       
    }

    &_hint{
        background:$color_white url("../img/icons/icon_lightbulb.svg") center left 10px no-repeat;
        background-size:12px;
        padding:8px 20px 8px 30px;
        border-radius:20px;
        font-style: italic;
        margin:10px 0;
        font-size:11px;
    }
    &_inner{
        height:auto;
        padding:8px 20px;
        border-radius:20px;
        background:$color_blue;
        color:$color_white;
        width:100%;
        box-sizing: border-box;
        
    }
    small{
        font-size:10px;
        text-transform: uppercase;
        margin-bottom:0px;
    }
    p{
        &.info{
            font-size:12px;
            font-style: italic;
        }
        img{
            height:18px;
            margin-left:10px;
            margin-bottom:-4px;
            cursor:pointer;
        }
    }
    .btn{
        font-size:12px;
        width:auto;
        padding:8px 12px;
        display:inline-block;
        margin:2px;
    }
}