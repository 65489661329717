.atom_error{
    background:$color_warning;
    padding:10px;
    color:$color_white;
    text-align: center;
    border-radius:10px;
    margin:20px 0;
}
.atom_success{
    background:$color_success;
    padding:10px;
    color:$color_black;
    text-align: center;
    border-radius:10px;
    font-size:12px;
    margin:20px 0;
}