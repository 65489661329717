.btn{
   border-radius:30px;
   padding:12px 28px;
   border:0;
   font-family: $font_secondary;
   transition:1s all;
   cursor: pointer;
   text-align: center;
   margin:10px 0;
   text-transform: capitalize;
   text-decoration:none !important;
   a{
    text-decoration:none !important;
   }
   &_add{
    background:rgba(255,255,255,0.3) url('../img/icons/icon_plus.svg') no-repeat center center;
    border:1px dashed $color_white;
    background-size:15px;
    padding:16px 20px;
    width:100%;
    box-sizing: border-box;
   }
   &_transparent{
    padding:0 !important;
    margin-top:10px !important;
    background:transparent;
    width:100%;
    display:block !important;
    text-align: left;
    &.white{
        color:$color-white;
        text-decoration:underline !important;
    }
   }
   &.disabled{
    cursor:help;
   }
   &.mini{
    padding:9px 15px;
    font-size:12px;
    font-weight:600;
    background-size:12px !important;
   }
   
   &_lgrey{
    background-color:$color_grey;
    color:$color_white;
    &.reset{
        background:$color_lgrey url('../img/icons/icon_reset.svg') no-repeat center right 15px;
        padding-right:40px !important;
        background-size:15px;
        color:$color_white;
        &:hover{
            background-color:$color_xl_blue;
            color:$color_blue;
        }
    }
   }
   &_blue{
    background:$color_xl_blue;
    color:$color_blue !important;
    &.edit{
        background:$color_xl_blue url('../img/icons/icon_edit.svg') no-repeat center right 10px;
        padding-right:40px !important;
    }
    &.new{
        background:$color_xl_blue url('../img/icons/icon_new.svg') no-repeat center right 10px;
        padding-right:40px !important;
       
    }
    &:hover{
        background-color: $color_green !important;
        color:$color-blue;

    }
   }
   &_coral{
    background:$color_coral;
    color:$color_white;
    
    &.generate{
        background:$color_coral url('../img/icons/icon_files_white.svg') no-repeat center right 15px;
        padding-right:40px !important;
        background-size:12px !important;
        color:$color_white;
        font-weight:600;
        text-transform:uppercase;
        font-size:11px;
        &:hover{
            background:$color_xl_green url('../img/icons/icon_files.svg') no-repeat center right 15px;
            color:#3f4d07;
        }
    }
   }
   &_green{
        background:$color_green;
        color:$color_black;
        &.save{
            background:$color_green url('../img/icons/icon_save.svg') no-repeat center right 15px;
            padding-right:40px !important;
            background-size:15px;
            color:#3f4d07;
            font-weight:600;
            text-transform:uppercase;
            font-size:11px;
            &:hover{
                background-color:$color_xl_green;
                color:#3f4d07;
            }
        }
        &.preview{
            background:$color_green url('../img/icons/icon_eye_open.svg') no-repeat center right 10px;
            padding-right:40px !important;
            &.disabled{
                background:$color_xl_green url('../img/icons/icon_eye_closed.svg') no-repeat center right 10px;
            }
        }
        &:hover{
            background-color: $color_blue;
            color:$color_white;
        }
   }
   &.icon_only{
        border-radius:100%;
        padding: 10px !important;
        height:40px;
        width:40px;
        background-size:15px;
        background-position: center center;
        &.generate{
            background-size:11px !important;
            &:hover{
                background:$color_xl_green url('../img/icons/icon_files.svg') no-repeat center center;
            }
            
        }
   }

   &_white{
    background:$color_white;
    color:$color_black;
        &:hover{
            background-color: $color_blue;
            color:$color_white;
        }
    }   
    
}