.pad{
    &_b{
        &_20{
            padding-bottom:20px;
        }
    }
    &_t{
        &_20{
            padding-top:20px;
        }
    }
}