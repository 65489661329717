.toggle_section{
    width:100%;
    padding-bottom:0px;
    padding-top:0px;
    margin-top:0;
    border-bottom:1px solid $color_lgrey;
    color:$color_blue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition:1s all;
    .icon_number{
        width:30px;
        
        text-align: right;
        position:absolute;
        right:75px;
        top:18px;
        font-size:15px;
        background:url("../img/icons/icon_file_check.svg") no-repeat left center;
        background-size:50%;
    }
    .icon_edit{
        width:55px;
        
        text-align: right;
        position:absolute;
        right:125px;
        top:18px;
        font-size:15px;
        background:url("../img/icons/icon_edit.svg") no-repeat left center;
        background-size:30%;
    }
    h4{
        margin-bottom:12px;
        margin-top:12px;
    }
    img{
        position:absolute;
        right:15px;
        top:15px;
        transform:rotate(45deg);
        cursor:pointer;
    }
    &.closed{
        border-bottom:0;
        border-radius:10px;
        background:$color_white;
        color:$color_blue;
        border-bottom:1px solid $color_white;
        img{
            transform:rotate(0deg);
        }
    }

}

.toggle_content{
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    max-height: 0;
    margin-bottom:30px;
    &.open{
        max-height:1000px;
        transition: max-height 0.5s ease-in;

    }
}

.component_toggle{
    border-radius:10px;
    padding:0px;
    background:white;
    position:relative;
    width:100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
    &.margin-up{
        margin-top:-50px;
    }
}

.atom_toggle{
    width:100%;
    background:$color_xl_blue;
    padding:10px;
    border-radius:20px;
    box-sizing: border-box;
    span{
        color:$color_blue;
        font-weight:700;
        text-align: center;
        font-size:12px;
        text-transform: uppercase;
        display:block;
    }
    .revision{
        display:flex;
        font-size:12px;
        width:100%;
        margin:10px 0;
        
        
        .timestamp{
            width:75%;
        }
        a{
            display:block;
            text-align: right;
            width:12%;
            img{
                height:17px;
            }
        }
    }
    &_title{
        cursor: pointer;
        text-align: left;
        font-size:12px;
        font-weight:800;
        color:$color_coral;
        
        img{
            float:right;
            margin-left:20px;
            transform:rotate(-90deg);
            transition: 1s all;
            margin-top:-2px;
            max-height:20px;
            &.closed{
                transform:rotate(90deg);
            }
        }

    }
    &_toggle{
        position: absolute;
        &.closed{
            display:none;
            
        }
        &.open{
            display:block;
        }
    }
    &_content{
        margin-top:10px;
        background:white;
        border-radius:10px;
        padding:10px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
        
        .link_section{
            width:100%;
            margin:10px 0;
            .link{
                &-settings{
                    cursor: pointer;
                    width:100%;
                    display:block;
                    background: url("../img/icons/icon_settings.svg") no-repeat center left;
                    padding-left:25px;
                    background-size:contain;
                    margin:20px 0;
                    transition:1s all;
                    &:hover{
                        color:$color_green;
                    }
                }
                &-logout{
                    cursor: pointer;
                    width:100%;
                    display:block;
                    background: url("../img/icons/icon_logout.svg") no-repeat center left;
                    background-size:contain;
                    padding-left:25px;
                    margin:20px 0 0 0;
                    transition:1s all;
                    &:hover{
                        color:$color_green;
                    }
                }
            }
        }
        .avatar_section{
            width:100%;
            display:block;
            clear:both;
            display:flex;
            .name{
                width:55%;
                font-size:18px;
                padding:10px 0;
                font-family: $font_primary;
            }
            .avatar{
                width:40%;
                .inner{
                    transition:1s all;
                    cursor: pointer;
                    border-radius:100%;
                    border:2px solid $color-white;
                    background:$color_xl_blue url("../img/icons/icon_user.svg") no-repeat center center;
                    background-size:50%;
                    height:20px;
                    width:20px;
                    padding:8px;
                }
            }
        }
    }
}