@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
/* ------
  -- FONT VARIABLES
  ------- */
  $font_primary: "Syne", sans-serif;
  $font_secondary: "Inter", sans-serif;

/* ------
  -- TYPOGRAPHY - HEADINGS
  ------- */



h1,h2,h3,h4, h5{
  font-family:$font_primary;
  font-optical-sizing: auto;
  font-style: normal;
}

h1{
  font-size:60px;
  line-height: 60px;
  font-weight:700;
  @media (max-width: $break-small) {
    font-size:40px;
    line-height: 40px;
  }
}

h2{
  font-size:40px;
  font-weight:700;
}

h3{
  font-size:30px;
  font-weight:700;
  @media (max-width: $break-small) {
    font-size:20px;
    font-weight:600;
  }
  &.global_title{
    @media (max-width: $break-small) {
      margin-top:80px;
    }

  }
}
h4{
  font-size:25px;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight:700;
}
h5{
  font-size:18px;
  font-weight:600;
  margin-bottom:5px;
  font-stretch: 0;
  font-optical-sizing: 0 !important;
}

h6{
  font-size:18px;
  font-weight:600;
  margin-bottom:5px;
  font-stretch: 0;
  font-optical-sizing: 0 !important;
  font-family: $font_secondary;
}

p, li, ol, input, blockquote{
  font-family: $font_secondary;
}

.ft_w{
  &_100{
    font-weight:100;
  }
  &_200{
    font-weight:200;
  }
  &_300{
    font-weight:300;
  }
  &_400{
    font-weight:400;
  }
  &_500{
    font-weight:500;
  }
  &_600{
    font-weight:600;
  }
  &_700{
    font-weight:700;
  }
  &_800{
    font-weight:800;
  }
  &_900{
    font-weight:900;
  }
}


/* ------
  -- TYPOGRAPHY - links
  ------- */

a{
  text-decoration: underline !important;
  color:$color_blue !important;
  transition:1s all;
  &:hover{
    color:$color_green !important;
  }
  &:visited{
    color:inherit !important;

  }
}

/* ------
  -- TYPOGRAPHY - Paragraphs
------- */

p{
  font-size: 15px;
  &.ft-small{
    font-size:11px;
    cursor:pointer;
  }
}

