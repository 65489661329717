.atom_half{
    position:relative;
    &_wrapper{
        display: flex;
        width:100%;
        flex: 1; /* Take up remaining space */
        overflow: hidden; /* Prevent overflow */
        @media (max-width: $break-small) {
            flex-direction: column;
        }
        &.top{
            flex-shrink: 0; /* Prevent shrinking */
            height: auto; /* Auto size based on content */
            max-height:170px;
            @media (max-width: $break-small) {
                margin-top:20px;
                max-height:24%;
                order:1;
                display:flex;
                flex-direction: column;
                .atom_half{
                    &:first-child{
                        order:2;
                    }
                    &:last-child{
                        order:1;
                    }
                }
            }
        }
        &.bottom{
            flex-grow: 1;
            overflow-y: auto; /* Allow scrolling if content overflows */
            @media (max-width: $break-small) {
                max-height:75%;
                order:2;
                position:relative;
                .atom_half{
                    &:first-child{
                        order:2;
                        height:auto;
                    }
                    &:last-child{
                        order:1;
                    }
                }
            }
        }
    }
    padding:0 10px;
    flex:1;
    box-sizing: border-box;
    @media (max-width: $break-large) {
        width:100%;
    }
   
    &.flex-end{
        display:flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: flex-end;
        align-content:flex-end;
        height:100%;
    }
}