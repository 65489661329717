.layout_footer{
    z-index:1;
    background:$color_black;
    img{
        max-height:50px;
    }
    a{
        transition:1s all;
        &:hover{
            color:$color_coral;
        }
    }
    ul{
        margin:0;
        padding:0;
        li{
            color:$color_white !important;
            font-size:14px;
            font-family: $font_primary;
            margin:8px 0;
            list-style: none;
        }
    }
    &-top{
        padding: 0px 30px;
        .col-xs-12{
            padding:0;
            border-bottom:1px solid rgba(255,255,255,0.4);
            a{
                margin-left:20px;
            }
        }
    }
    &-bottom{
        padding: 20px 30px;
        .col-xs-12{
            border-top:1px solid rgba(255,255,255,0.4);
            padding-left:0;
            &:last-child{
                padding-right:0;
                text-align: right;
            }
        }
        div{
            display:inline;
            color:$color_white !important;
            font-size:14px;
            font-family: $font_primary;
            &:last-child{
                padding-left:15px;
            }

        }
    }
}