.layout_pricing{
    z-index:1;
    margin:140px auto 50px;
    @media (max-width: $break-small) {
    &_mobile{
        height:100%;
        position:relative;
        overflow:auto;
        &_inner{
            overflow:auto;
            height:auto;
            .buttons{
                display:block !important;
                box-sizing: border-box;
            }
            .btn{
                width:100%;
                display:block;
                box-sizing: border-box;
                margin:5px 0 0 0;
            }
        }
    }
    }
}