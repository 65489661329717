.atom_tender{
    &_close{
        position:absolute;
        top:20px;
        right:20px;
        font-size:15px;
        cursor: pointer;
        color:$color-white;
        display: flex;
        height:30px;
        width:30px;
        font-weight: bold;
        background:$color_coral;
        border-radius:100%;
        align-items: center;
        justify-content: center;
    }
    &_preview{
        width:100vw;
        height:100vh;
        transition:1s all;
        top:0;
        position:absolute;
        backdrop-filter: blur(10px);
        z-index:999999;
        right:-200%;
        animation: slideOutRight 1s ease-out forwards;
        &.open{
            right:0;
            animation: slideInRight 1s ease-out forwards;
        }
    
    }
}