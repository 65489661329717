form{
    box-sizing: border-box;
    label{
        display: block;
        font-family: $font_secondary;
        margin:20px 0 10px 0;
        font-size:13px;
        font-weight:300;
        font-size:16px;
    }
    .fieldset{
        &.multi{
            margin-top:10px;
        }
        &_submit{
            margin:30px 0;
            &.float{
                position:absolute;
                right:30px;
                top:50px;
            }
        }
    }
    select{
        box-sizing: border-box;
        background-color:rgba(255,255,255,0.5);
        border:$color_white 2px solid;
            border-radius:20px;
            width:100%;
            padding:15px 15px;
            color:$color_grey;
            transition:1s all;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
    }
    
    input{
        &[type=text],
        &[type=email],
        &[type=password],
        &[type=type]
        {
            box-sizing: border-box;
            background-color:rgba(255,255,255,0.5);
            border:$color_white 2px solid;
            border-radius:20px;
            width:100%;
            padding:10px 15px;
            color:$color_grey;
            transition:1s all;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
            &:focus{
                outline:none;
                background-color:$color_xl_blue;
                border-color: $color_blue;
            }
            &::placeholder{
                color:$color_grey;
            }
        }
    }

    .input{
        &-user{
            background-image: url("../img/icons/icon_user.svg");
            background-position: left 15px center;
            background-repeat: no-repeat;
            padding-left:55px !important;
        }
        &-email{
            background-image: url("../img/icons/icon_email.svg");
            background-position: left 15px center;
            background-repeat: no-repeat;
            padding-left:55px !important;
        }
        &-password{
            background-image: url("../img/icons/icon_lock.svg");
            background-position: left 15px center;
            background-repeat: no-repeat;
            padding-left:55px !important;
        }
    }

    
}

.atom_input{
    position:relative;
    display:flex;
    width:100%;
    margin-bottom:10px;
    form{
        width:100%;
    }
    textarea{
        box-sizing: border-box;
        background:$color_xl_blue url('../img/icons/icon_pin.png') no-repeat bottom 15px left 15px;
        background-size:20px;
        font-family: $font_secondary;
        font-size:14px;
        border:1px solid white;
        border-radius:20px;
        padding:15px 40px;
        width:100%;
        
    }
    button{
        background:transparent url('../img/icons/icon_send.svg') no-repeat center center ;
        height:25px;
        width:25px;
        background-size:cover;
        overflow:hidden;
        border:0;
        position:absolute;
        right:15px;
        margin-top:4px;
        z-index:9999;
        text-indent:9999px;
        cursor:pointer;
        transition:1s all;
        &:hover{
            opacity:0.3
        }

    }
}
.overflow{
    overflow-y:scroll;
    height:auto;
    max-height:83vh;

}
.form_section{
    height:auto;
    overflow-y: scroll;
    textarea{
        height:100px;
        box-sizing: border-box;
        background-color:rgba(255,255,255,0.5);
        border:$color_white 2px solid;
            border-radius:20px;
            width:100%;
            padding:15px 15px;
            color:$color_grey;
            transition:1s all;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px;
    }
}