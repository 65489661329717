.atom_progress{
    width: 100%;
    margin:0px auto;
    background-color: $color_xl_blue;
    border-radius: 50px;
    padding: 1px;
    position:relative;
    height:30px;
    @media (max-width: $break-small) {
        width: 30%;
    }
    .progress-bar {
        height: 30px;
        width: 0;
        border-radius: 50px;
        text-align: center;
        line-height: 30px;
        color: white;
        transition: width 0.3s ease;
        font-size:10px;
        &.bg_green{
            p{
                color:$color_blue;
            }
        }
        p{
            font-size:10px;
            font-family:$font_secondary;
            color:$color_white;
            padding:0;
            margin:0;
            transition:1s all;
        }
    }
    &:after{
        content:'';
        background:url('../img/icons/icon_loading.svg') no-repeat center right;
        background-size:contain;
        position:absolute;
        top:3px;
        right:15px;
        height:25px;
        width:25px;
    }
}


