.layout_hero{
    z-index:1;
    &-main{
        height:100vh;
    }
    &-mini{
        padding:100px 0;
        a{
            &:last-child{
                margin-left:20px;
            }
        }
        h1{
            margin-bottom:0;
        }
        .pricing{
            font-size:26px;
            font-weight:700;
            text-transform:uppercase;
            color:$color_white;
            font-family:$font_primary;
        }
    }
}