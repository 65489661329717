// -- ANIMATION - Fades
  @keyframes fadeUp {
    from {
      opacity:0;
      margin-bottom:-200px;
    }
    to {
      opacity:1;
      margin-bottom:0;
    }
  }

  @keyframes fadeDown {
    from {
      opacity:0;
      margin-bottom:-0px;
    }
    to {
      opacity:1;
      margin-bottom:-200px;
    }
  }

  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
// -- ANIMATION - Slides
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%); // Start offscreen
  }
  to {
    opacity: 1;
    transform: translateY(0); // Slide in from left
  }
}

@keyframes slideOut {
  0% {
    left:0; // Start offscreen
  }
  99% {
    left:160%; // Slide in from left
    
  }
  100%{
    left:-160%; // Slide in from left
  }
}

@keyframes slideInRight {
  0% {
    right:-200%; // Start offscreen
  }

  100%{
    right:0; // Slide in from left
  }
}
@keyframes slideOutRight {
  0% {
    right:-200%; // Start offscreen
  }

  100%{
    right:0; // Slide in from left
  }
}

// -- ANIMATION - Diamond
@keyframes diamond-grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

// -- ANIMATION -- Input
@keyframes placeholder-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(1.05);
      opacity: 0.7;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

// -- ANIMATION - Scroll
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

// -- ANIMATION - Bounce
@keyframes bounce {
  0% {
    margin-top:0px;
  }
  40% {
    margin-top:10px;
  }
  60%{
    margin-top:3px;
  }
  80%{
    margin-top:7px;
  }
  100% {
    margin-top:0px;
  }
}

// -- ANIMATION - Blink
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

// -- ANIMATION - Marquee
@keyframes marquee {
  0%, 100% { transform: translateX(0%) }
  50% {  transform: translateX(-100%); }
  
}

.zoomy{
  animation: slideIn 1s ease-out forwards;
}

