.atom_menu {
    cursor: pointer;
    margin: 0 auto;
    padding-left: 1.25em;
    position: relative;
    top:5px;
    right:0px;
    width: 15px;
    height: 22px;
    z-index:999999999;
    transition:1s all;
    float:right;
    @media (max-width: $break-small) {
      float:right;
    }
    
  
    .menu-global {
      backface-visibility: hidden;
      position: absolute;
      left: 0;
      border-top: 2px solid white; // White bars as specified
      width: 100%;
      transition: 0.55s;
      z-index:9999999;
    }
  
    .menu-top {
      top: 0;
    }
  
    .menu-middle {
      top: 9px;
    }
  
    .menu-bottom {
      top: 18px;
    }
    &.clicked{
      background:$color_coral;
      height:13px;
      padding:10px;
    }
  
    .menu-top-click {
      backface-visibility: hidden;
      top: 15px;
      transform: rotate(50deg);
      transition: 0.55s 0.5s;
    }
  
    .menu-middle-click {
      opacity: 0;
    }
  
    .menu-bottom-click {
      backface-visibility: hidden;
      top: 15px;
      transform: rotate(-410deg);
      transition: 0.55s 0.5s;
    }

    &_content{
        position:fixed;
        z-index:99999;
        background:$color_black;
        padding:30px;
        width:30%;
        max-width:320px;
        top:0;
        right:-100%;
        height:100vh;
        transition:1s all ease-in-out;
        display:flex;
        flex-direction: column;
        box-sizing: border-box;
        background:$color_black;
        @media (max-width: $break-small) {
          width:80vw;
        }
        &.active{
            right:0;
        }
        &_bottom{
            max-height:10vh;
            @media (max-width: $break-small) {
              margin-bottom:20vh;
            }
            border-top:$color_white 1px solid;
            .logo_section{
                font-family:$font_primary;
                color:$color_coral;
                font-size:30px;
                padding-top:20px;
                font-weight:900;
                width:50%;
                float:left;
                img{
                    height:30px;
                }
            }
            .login_section{
                color:white;
                float:right;
                text-align: right;
                width:50%;
                padding-top:25px;
                cursor:pointer;
                img{
                    max-height:27px;
                    float:right;
                    text-align: right;
                    max-width:32px;
                    transition:1s all;
                    opacity:1;
                    &:hover{
                        opacity:0.4;
                    }
                }
            }
        }

        ul{
            margin:0;
            padding:30px 0;
            height:90vh;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width:100%;
            position:relative;
            box-sizing: border-box;
            @media (max-width: $break-small) {
              height:80vh;
            }
            &.bottom{
              height:auto;
              border-top:$color_black 1px solid;
            }
            li{
                color:$color_white;
                transition:1s all;
                font-size: 34px;
                width:100%;
                font-weight:600;
                text-transform:uppercase;
                text-align: left;
                font-family: $font_primary;
                list-style: none;
                padding:4px 0;
                cursor:pointer;
                a{
                  color:inherit;
                  text-decoration: none;;
                }
                
                &:hover{
                    color:$color_coral;

                }
            }
        }
    }
  }
  