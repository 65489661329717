.video-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -2;
  }
  
  .video-bg {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .app_mask{
    position:absolute;
    z-index:-1;
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
  }