body{
  
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

a{
    text-decoration:none !important;
    &:visited{
        color:inherit;
    }
    .btn {
      text-decoration:none !important;
    }
}


.bg-animation{
  position: absolute;
  top:0;
  left:0;
  z-index: 0;
  width:100vw;
  height:100vh;

  .circle-1{
      position: fixed;
      left: auto;
      top: 0%;
      right: 0%;
      bottom: auto;
      z-index: 0;
      width: 50vw;
      height: 100vh;
      border-top-left-radius: 20%;
      border-top-right-radius: 20%;
      border-bottom-left-radius: 20%;
      border-bottom-right-radius: 20%;
      background-color: hsla(216, 79.34%, 51.60%, 0.50);
      background-image: url('../img/general/circle_bg.svg');
      background-position: 0px 0px;
      background-size: auto;
      opacity: 0.7;
      filter: blur(50px);
    
  }
  .circle-2{

      position: fixed;
      left: 0%;
      top: 0%;
      right: auto;
      bottom: 0%;
      z-index: 0;
      width: 60vw;
      height: 50vh;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      background-color: hsla(168, 79.28%, 43.53%, 0.42);
      opacity: 0.57;
      filter: blur(40px);
  }
  .circle-3 {
    position: fixed;
    left: 0%;
    top: auto;
    right: auto;
    bottom: -36%;
    z-index: 0;
    width: 40vw;
    height: 80vh;
    border-top-left-radius: 80%;
    border-top-right-radius: 80%;
    border-bottom-left-radius: 80%;
    border-bottom-right-radius: 80%;
    background-color: hsla(220.48192771084337, 87.37%, 81.37%, 1.00);
    opacity: 0.4;
    filter: blur(40px);
  }
  .circle-5 {
    position: fixed;
    left: 24%;
    top: auto;
    right: 0%;
    bottom: -3%;
    z-index: 0;
    width: 60vw;
    height: 50vh;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    background-color: hsla(216, 63.97%, 83.35%, 1.00);
    opacity: 0.6;
    filter: blur(40px);
  }
}


#root{
  overflow-x: hidden;
}

/* ------
  -- Heights / Widths
  ------- */
.height{
  &_full{
    height:100vh;
  }
  &_100{
    height:100%;
  }
  &_half{
    height:50vh;
  }
  &_200{
    height:200px;
  }
  &_300{
    height:300px;
  }

}

.width{
  &_100{
    width:100%;
  }
  &_full{
    width:50vh;
  }
}

.flex{
  &-bottom{
    align-content: end;
    justify-content: end;
    align-items: end;
  }
  &-column{
    flex-direction: column;
  }
}
/* ------
  -- CONTAINERS
  ------- */

  .inner-wrap{
    padding:2em;
    display:flex;
    
  }

  @media (min-width: $break-xxlarge) {
    .row{
      max-width:$break-xxlarge + 100px;
      margin:0 auto;
      &.container_align--bottom{
        min-width:auto;
      }
      
    }
  }

.container{
  &-fluid{
    margin:0;
    >.row{
      max-width:100% !important;
    }
  }
  &_multi{
    padding:30px 48px 30px 48px;
    height:60vh;
  }
  &_fix{
    position:sticky;
    z-index:9999;
    top:145px;
  }
  &_with--small-padding{
    padding:160px 0px 60px 0px;
    @media (max-width: $break-medium) {
      padding:100px 20px 60px 20px;

    }
  }
  &_with--padding{
      padding:60px 48px 60px 48px;
      @media (max-width: $break-medium) {
        padding:40px 20px;
      }
      &--video{
        padding:0px 18px 60px 18px;
        margin-top:-50px;
      }
      
      &--block{
        padding:0px 48px 60px 48px;
        @media (max-width: $break-medium) {
          padding:0px 20px 40px 20px;
        }
        &.bg_light{
          padding:0px 48px 30px 48px;
          @media (max-width: $break-medium) {
            padding:0px 20px 30px 20px;
          }
        }
      }
  }
  &_no--padding{
      padding:0;
      .col-xs-3{
          padding:0;
      }
  }
  &_width--full{
      height: 100vh;
      display: flex; 
      align-items: center; 
      justify-content: center; 
      overflow: hidden;
      position:relative;
      z-index:1;
  }
  &_align--bottom{
      
      min-width:90vw;
      height:100%;
      
      display: flex;
      align-items: flex-end;
      @media (max-width: $break-medium) {
       padding-bottom:40px;

      }
  }
}
.np{
  padding:0;
}
.nph{
  padding-top:0;
  padding-bottom:0;
  padding-left:15px;
  padding-right:15px;
}


/* ------
  -- ALIGNS
  ------- */
.align{
  &_right{
    float:right;
  }
}

/* ------
  -- FLEX
  ------- */
.flex{
    &_center{
        align-items: center;
        justify-content: center;
        display:flex;
        &-end{
          align-items: center;
          justify-content: end;
          display:flex;
        }
    }
    &_middle{
      justify-content: center;
    }
    &_bottom{
      display: flex;
      align-items: end;
  }
}




  /* ------
  -- HIDE MOBILE / DESKTOP
  ------- */

  .mobile_hide{
    @media (max-width: $break-small) {
        display:none !important;
    }
  }

.tablet_hide{
    @media (max-width: $break-xlarge) {
        display:none !important;
    }
}

.mobile_show{
  display:none;
    @media (max-width: $break-small) {
        display:block;
    }
}


.tablet_show{
    display:none;
    @media (max-width: $break-xlarge) {
        display:block;
    }
}

.mobile-center{
  @media (max-width: $break-medium) {
    text-align: center;
  }
}
