.atom_system_agent{
    background:$color_xl_blue url('../img/icons/icon_agent.svg') no-repeat center center;
    background-size:25px;
    border-radius:100%;
    width:50px;
    height:50px;
    border:3px solid $color_blue;
    &.pulse {
        animation: pulse-animation 2s infinite;
    }
    &.mini{
        width:24px;
        height:24px;
        min-width:24px;
        min-height:24px;
        background-size:12px;
        margin-right:10px;
        
    }
    &.error{
        background-color:$color_xl_coral;
        border:3px solid $color_coral;
    }
}